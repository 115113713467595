.documentation-section {
  padding: 15vh 20%;
  display: flex;
  flex-direction: column;
  gap: 3em;
}

.documentation-section-header {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.documentation-section-header-endpoint {
  background: var(--secondary);
  padding: 0.5em 1em;
  color: var(--disabled);
  border-radius: .5em;
}

.documentation-section-body {
  display: flex;
  flex-direction: row;
}

.documentation-section-body-parameters {
  flex: 3;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.documentation-section-body-parameters li {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.documentation-section-body-parameters-definition {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  font-size: 1.1em;
}

.documentation-section-body-parameters-definition span {
  color: var(--disabled);
}

.paragraphs-margin p {
  margin: 1em 0;
}
