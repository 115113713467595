.footer {
  display: flex;
  flex-direction: column;
  gap: 1em;

  background: #283535;
  color: #FFF;
  
  position: sticky;

  left: 0;
  top: 0;

  z-index: 1;

  padding: 1em 10%;
}

.footer-disclaimers {
  font-size: 0.8em;
}

@media screen and (min-width: 800px) {
  .footer {
    flex-direction: row;
  }

  .footer-disclaimers {
    margin-left: auto;
    text-align: right;
  }
}