:root {
  --background: #FFF;
  --color: #283535;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;

  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  font-size: 18px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--background);
  color: var(--color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: 2.3em;

  line-height: 1.1em;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

a {
  color: #4286F4;
  text-decoration: none;
}

header a {
  color: inherit;
}

b {
  font-weight: 500;
}

main {
  background: var(--background);
}

.container {
  max-width: 1300px;

  margin: 0 auto;
}
