.page-header {
  display: flex;
  flex-direction: row;
  gap: 1em;
  
  position: sticky;

  left: 0;
  top: 0;

  z-index: 1;

  background: var(--background);
  color: var(--color);
  
  padding: 1em 10%;
}

.page-header-links {
  display: flex;

  flex-direction: row;
  align-items: center;
 
  margin-left: auto;

  gap: 3em;
}

.page-header-links a {
  font-size: 1.2em;
  font-weight: 500;
}

@media only screen and (max-width: 1100px) {
  .page-header {
    flex-direction: column;

    align-items: center;
  }

  .page-header-links {
    margin: 0 auto;
  }
}