.landing-view {
  height: 100vh;
  width: 100%;

  background-color: #FFF;

  padding: 1em;
  
  box-sizing: border-box;

  overflow: hidden;
}

.landing-view-container {
  display: flex;
  flex-direction: column;

  align-items: center;

  position: relative;
}

.landing-view-intro {
  display: flex;

  padding: 4em 0;
  
  flex-direction: column;

  gap: 1.5em;

  z-index: 2;
}

.landing-view-intro p {
  color: #6A706E;

  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}

.landing-view-badges {
  margin-top: 1em;

  display: flex;
  height: 3.5em;
  gap: 1em;
}

.landing-view-badges a {
  flex: 1;
}

.landing-view-badges a img {
  height: 100%;
  width: 100%;

  object-fit: contain;
}

.landing-view-vector {
  position: relative;

  /*right: -10%;
  width: 50%;*/

  pointer-events: none;
}

.landing-view-vector-shape {
  position: absolute;

  left: -10%;
  top: -20%;

  width: 120%;
  height: 120%;
  
  z-index: 0;
}

.landing-view-vector-image {
  position: relative;

  width: 100%;
  height: 100%;
 
  z-index: 1;
}

.landing-view-screenshot {
  max-width: 80%;
  max-height: 60vh;
  max-width: 40vw;
}

.landing-view-screenshot:nth-child(1) {
  transform: rotateZ(-2deg);
  z-index: 3;
}

.landing-view-screenshot:nth-child(2) {
  transform: rotateZ(4deg);
  margin-left: -5%;
  z-index: 0;
}

@media screen and (min-width: 800px) {
  .landing-view:nth-child(odd) .landing-view-container {
    flex-direction: row;
  }

  .landing-view:nth-child(even) .landing-view-container {
    flex-direction: row-reverse;
  }

  .landing-view-container {
    height: 100%;
  }

  .landing-view-intro h2 {
    font-size: 3.5em;
  }

  .landing-view .landing-view-intro {
    max-width: 55%;
  }

  .landing-view:nth-child(1) .landing-view-intro {
    max-width: 65%;
  }

  .landing-view-badges a {
    flex: unset;

    height: 100%;
  }
  
  .landing-view-badges a img {
    height: 100%;
    width: unset;
  }

  .landing-view-vector {
    position: absolute;
  }

  .landing-view:nth-child(odd) .landing-view-vector {
    right: -10%;
    width: 50%;
  }

  .landing-view:nth-child(even) .landing-view-vector {
    left: -10%;
    width: 50%;
  }
}
