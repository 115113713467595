.word-input {
  border-bottom: 0.2em solid var(--brand);

  display: flex;
  flex-direction: row;
  gap: 0.5em;
  font-size: 1.2em;

  align-items: baseline;

  width: 100%;
  flex-wrap: wrap;
}

.word-input-item {
  background: var(--brand);
  color: var(--brandColor);
  border-radius: 0.5em;
  padding: 0.25em;

  display: flex;
  flex-direction: row;
  gap: 0.25em;
  align-items: center;
}

.word-input-delete {
  cursor: pointer;
}

.word-input-field {
  font-size: inherit;
  padding: 0.5em;
  border: none;
  background: none;
  flex: 1;
}
